<template>
  <main class="page-markers page-edit-marker">
    <PageTitleContainer>
      <PageTitle subtitle="Modifica" removeMargin>{{ module.name }}</PageTitle>

      <div class="text-right mb-3">
        <router-link class="btn btn-outline-secondary" :to="{ name: 'modules.show' }">Annulla modifica</router-link>
      </div>
    </PageTitleContainer>
    <div class="container">
      <ModuleForm :feedback.sync="feedback" :module="moduleToEdit" @submitForm="submit" edit />
    </div>
  </main>
</template>

<script>

export default {
  name: 'module-edit',
  components: {
    PageTitle: () => import('@/views/components/Layout/PageTitle'),
    PageTitleContainer: () => import('@/views/components/Layout/PageTitleContainer'),
    ModuleForm: () => import('@/views/components/Form/ModuleForm'),
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    module: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      moduleToEdit: {},
      feedback: null,
    };
  },
  methods: {
    updateModuleToEdit (module) {
      this.moduleToEdit = {
        ...module,
        projectId: module?.project?.id || null,
        ...this.moduleToEdit,
      };
    },
    submit (moduleToEdit) {
      const module = { ...moduleToEdit };

      this.$api.updateModule(module.id, module)
        .then(res => {
          this.feedback = true;
          this.$emit('update:module', res.data);
          this.moduleToEdit = {};
          this.$router.push({ name: 'modules.show' });
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
  },
  mounted () {
    this.updateModuleToEdit(this.module);
  },
  watch: {
    module (newValue) {
      this.updateModuleToEdit(newValue);
    },
  },
};

</script>
